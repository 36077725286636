import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingHeadingComponent } from '@app/shared/ui/onboarding-heading/onboarding-heading.component';

@Component({
  selector: 'app-create-account-layout',
  standalone: true,
  imports: [CommonModule, OnboardingHeadingComponent],
  templateUrl: './create-account.layout.component.html',
  styleUrls: ['./create-account.layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountLayoutComponent {
  @Input() pageTitle!: string;
}
