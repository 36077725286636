<div class="create-account__container min-h-full w-full">
  <!-- left side -->
  <div class="left-static__container hidden w-0 xl:flex flex-col">
    <div class="logo__container">
      <a href="#" class="cursor-pointer flex w-fit">
        <img
          class="w-auto mango-logo"
          src="/assets/images/svgs/mamvest-logo.svg"
          alt="Mamvest Logo"
        />
      </a>
    </div>

    <div class="welcome__container flex flex-col">
      <img
        class="investment-photo"
        src="/assets/images/svgs/investment-vault.svg"
        alt=""
      />

      <div class="flex flex-col welcome-text__box text-mango-black17">
        <h3 class="text-3xl font-black">
          Welcome to <span class="text-mango">Mango Asset Management!</span>
        </h3>
        <p class="text-sm">
          Discover a world of investment opportunities tailored to meet your
          needs.
        </p>
        <img src="/assets/images/svgs/dots-and-dash.svg" alt="" />
      </div>
    </div>
  </div>

  <!-- right side -->
  <div class="right-dynamic__container flex flex-col">
    <div class="dynamic__content h-full w-full">
      <ng-content></ng-content>
    </div>
  </div>
</div>
